import React, { useRef, useState } from "react";
import { Parallax } from 'react-scroll-parallax';
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";


/* Components */
import ChipIcon from "./parts/ChipIcon";
import ChipLink from './parts/ChipLink';
import LongCard from './parts/LongCard';
import Card from './parts/Card';
import SocialLink from './parts/SocialLink';
import Skill from './parts/Skill';
import CardFull from './parts/CardFull';
import Picture from './parts/Picture';
import Brand from "./parts/Brand";

/* Pictures */
import corentin from '../assets/pictures/corentin.webp';
import commercialGuy from '../assets/pictures/commercial-guy.webp';
import multiverseGuy from '../assets/pictures/multiverse-guy.webp';

/* Portfolio */
import portfolioBaroq from '../assets/portfolio/portfolio-baroq-sm.webp';
import portfolioDigitalize from '../assets/portfolio/portfolio-digitalize-sm.webp';
import portfolioWebsites from '../assets/portfolio/portfolio-websites-sm.webp';
import portfolioTraqpad from '../assets/portfolio/portfolio-traqpad-sm.webp';
import portfolioMoneyTimeConseil from '../assets/portfolio/portfolio-moneytimeconseil-sm.webp';
import portfolioOnlyCustomPop from '../assets/portfolio/portfolio-onlycustompop-sm.webp';

/* Icons */
import iconLinkedInIn from '../assets/icons/brands/linkedin-in-brands.svg';
import iconLinkedIn from '../assets/icons/brands/linkedin-brands.svg';
import iconBarChart from '../assets/icons/chips/material-symbols_bar-chart.svg';
import iconDesign from '../assets/icons/chips/material-symbols_design.svg';
import iconDone from '../assets/icons/chips/material-symbols_done.svg';
import iconLightbulb from '../assets/icons/chips/material-symbols_lightbulb.svg';
import iconMailOutline from '../assets/icons/chips/material-symbols_mail-outline.svg';
import iconPhoneEnabledSharp from '../assets/icons/chips/material-symbols_phone-enabled-sharp.svg';
import iconClose from '../assets/icons/chips/material-symbols_close.svg';

/* Brand */
import brandTraqpad from '../assets/brands/traqpad-black.svg';
import brandMTC from '../assets/brands/money-time-conseil-black.svg';
import brandLCA from '../assets/brands/cuisine-artisanale-black.svg';
import brandVCSH from '../assets/brands/vichy-celestins-spa-hotel-black.svg';
import brandSVSP from '../assets/brands/vins-de-saint-pourcain-black.svg';
import brandPLR from '../assets/brands/bijouterie-pelissier-la-regence-black.svg';
import brandAxial from '../assets/brands/axial-black.svg';
import brandCider from '../assets/brands/cider-black.svg';
import brandAssasImmobilier from '../assets/brands/assas-immobilier-black.svg';
import brandCRCDC from '../assets/brands/crcdc-black.svg';
import brandVintageDrivers from '../assets/brands/vintage-drivers-black.svg';
import brandPipette from '../assets/brands/pipette-black.svg';
import brandMDF from '../assets/brands/mdf-black.svg';
import brandBarnes from '../assets/brands/barnes-black.svg';
import brandCos from '../assets/brands/cos-black.svg';
import brandSadone from '../assets/brands/sadone-black.svg';
import brandMichalak from '../assets/brands/michalak-black.svg';
import brandProtextiles from '../assets/brands/protextiles-black.svg';
import brandArtling from '../assets/brands/artling-black.svg';

/* Files */
import CV from '../assets/CORENTIN_GOULOUMY_2023.pdf';

/* Styles */
import '../styles/App.scss';
import "swiper/css";

function App() {
  return (
    <div id="page">

      <section className="grid right">
        <div className="row row-2 side-right rg10 fullheight">
          <div className='col-small spacing-6 o2'>
            <div className='spacing-2'>
              <div className='spacing-1'>
                <div><p class="text-1">Hey ! Je suis</p></div>
                <div><h1 className="text-logo"><b>CORENTIN</b> GOULOUMY</h1></div>
                <div><span class="separator"></span></div>
                <div><h2 className="dark-blue">Head of Design, UX/UI</h2></div>
              </div>
              <div>
                <p>Concepteur puis Chef de Projet Digital, j’ai conceptualisé et intégré des sites internet en tous genres ainsi que des applications web & mobiles. Je me spécialise aujourd’hui en UX/UI.</p>
              </div>
            </div>
            <div class="flex-row g2">
              <ChipLink type="light" text="Mon CV" link={CV}></ChipLink>
              <ChipLink type="dark" text="Me contacter" link="#contact"></ChipLink>
            </div>
            <div class="flex-row">
              <SocialLink icon={iconLinkedInIn} text="LinkedIn" link="https://www.linkedin.com/in/corentin-gouloumy/"></SocialLink>
            </div>
          </div>
          <div className='col-big o1'>
            <Parallax speed={20} translateY={['200px', '-100px']}>
              <img src={corentin} className="corentin" alt="Corentin Gouloumy" />
            </Parallax>
          </div>
        </div>
        <div class="links-middle-shape"></div>
      </section>

      <section className='grid fullscreen'>
        <div className='row row-3 rg5 pbot-0'>
            <LongCard icon={iconDone} type="light" title="Chef de Projet" description="Je pilote votre transformation digitale"></LongCard>
            <LongCard icon={iconDone} type="dark" title="UI/UX Designer" description="Je fais des sites internet et des applications mobiles"></LongCard>
            <LongCard icon={iconClose} type="medium" title="Freelance" description={"Pas disponible en ce moment"}></LongCard>
        </div>
        <div class="card-left-shapes"></div>
      </section>

      <section className="grid left zi-1-">
        <div className="row row-2 side-left rg10 ptop-20">
          <div className='col-big o1'>
            <Parallax speed={40} translateY={['200px', '-200px']} translateX={['-200px', '200px']}>
              <img src={commercialGuy} className="commercial-guy" alt="Corentin Gouloumy" />
            </Parallax>
          </div>
          <div className='col-small spacing-4 o2'>
            <div className='spacing-2'>
              <div><h2>Besoin d’un designer <br/>créatif ? Je suis là !</h2></div>
              <div><p>J’ai déjà conçu et développé des dizaines de sites internet et applications. Un projet digital n’est pas seulement un projet qui doit être joli, il doit être efficace et doit respecter des contraintes bien précises. <b>Mélanger le fonctionnel et l’attrayant, voilà mon vrai métier.</b></p></div>
            </div>
            <div className="flex-row">
              <ChipLink type="light" text="Retrouvez-moi chez SERUM AND CO !" link="https://serumandco.com/" target="blank"></ChipLink>
            </div>
          </div>
        </div>
        <div class="dotted-line"></div>
        <div class="background"></div>
        <div class="shape right"></div>
      </section>

      <section className="grid zi-1-">
        <div className='row row-2 rg10'>
          <div className='col-small spacing-2'>
            <div><h2>Mes supers compétences</h2></div>
            <div><p>D’abord lors de mes études puis lors de mes expériences professionnelles, j’ai appris à maîtriser l’algorithmie, le game & web design et la communication. <b>Ma spécialité : le design !</b></p></div>
            <Skill software="FIGMA, XD" percent="90"></Skill>
            <Skill software="PHOTOSHOP, ILLUSTRATOR, INDESIGN" percent="80"></Skill>
          </div>
          <div className='col-small'>
            <div className='grid'>
              <div className='row row-2 cg2 phone-only spacing-tablet-2 ptop-0 pbot-0'>
                <div className='spacing-2 ptop-4'>
                  <Card type="dark" icon={iconDesign} text="UI/UX Design"></Card>
                  <Card type="dark" icon={iconLightbulb} text="Design Thinking"></Card>
                </div>
                <div className='spacing-2'>
                  <Card type="dark" icon={iconBarChart} text="Marketing"></Card>
                  <CardFull title="Et tant d'autres" text="Responsive, Chaîne Graphique, Gestion de Projet, Site Internet, Application Mobile"></CardFull>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className='grid left-small zi-1-'>
        <div className='row'>
          <div className='spacing-6 o2'>
            <div className='spacing-2'>
              <div><h2>Quelques projets sur lesquels j’ai travaillé</h2></div>
              <div><p>Voici une petite galerie reprenant les projets clés de ces dernières années !</p></div>
            </div>
          </div>
        </div>
      </section>

      <section className='grid fullscreen zi-1-'>
        <div className='row row-3a ptop-0 pbot-0'>
          <Picture file={portfolioWebsites}></Picture>
          <Picture file={portfolioOnlyCustomPop}></Picture>
          <Picture file={portfolioDigitalize}></Picture>
        </div>
      </section>
      <section className='grid fullscreen zi-1-'>
        <div className='row row-3b ptop-2'>
          <Picture file={portfolioTraqpad}></Picture>
          <Picture file={portfolioMoneyTimeConseil}></Picture>
          <Picture file={portfolioBaroq}></Picture>
        </div>
        <div class="shape left"></div>
      </section>

      <section className="grid right overflow zi-0">
        <div className="row row-2 side-right rg10 pbot-20">
          <div className='col-small spacing-6'>
            <div className='spacing-2'>
              <div><h2>Déjà dans l'avenir !</h2></div>
              <div><p>Formé en études supérieures mais également de manière autodidacte depuis ma tendre enfance, il est naturel pour moi de poursuivre mes recherches, même au sein d’un poste ou d’un projet.</p></div>
              <div><p>Je forme également mes collaborateurs et mes clients pour qu’ils puissent aisément prendre la main et devenir autonome dans l’administration de leurs produits.</p></div>
            </div>
          </div>
          <div className='col-big'>
            <Parallax speed={40} translateY={['200px', '-200px']}>
              <img src={multiverseGuy} className="multiverse-guy" alt="Corentin Gouloumy" />
            </Parallax>
          </div>
        </div>
        <div class="dotted-line pos-1"></div>
        <div class="background pos-1"></div>
      </section>

      <section className="grid zi-1-">
        <div className="row row-1 rg10 ptop-0">
          <div className='spacing-6 o2'>
            <div className='spacing-2 text-center'>
              <div><h2>Marques avec lesquelles j'ai travaillé</h2></div>
              <div>
                <p>En agence ou en freelance, j’ai travaillé avec des marques de tous horizons.</p>
                <p>En voici un bref échantillon !</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="grid fullswipe">
        <div className="row row-block ptop-0 pbot-20">
          <Swiper className="brands-swiper"
            slidesPerView={1}
            loop={true}
            autoplay={{
              delay: 2500,
            }}
            modules={[Autoplay]}
            breakpoints={{
              640: {
                centeredSlides: true,
                slidesPerView: 2,
              },
              1000: {
                slidesPerView: 1,
              },
              1300: {
                centeredSlides: false,
                slidesPerView: 5,
              },        
            }}
          >
            <SwiperSlide><Brand picture={brandMTC} height={"53px"}></Brand></SwiperSlide>
            <SwiperSlide><Brand picture={brandVCSH} height={"37px"}></Brand></SwiperSlide>
            <SwiperSlide><Brand picture={brandTraqpad} height={"54px"}></Brand></SwiperSlide>
            <SwiperSlide><Brand picture={brandLCA} height={"80px"}></Brand></SwiperSlide>
            <SwiperSlide><Brand picture={brandSVSP} height={"125px"}></Brand></SwiperSlide>
            <SwiperSlide><Brand picture={brandPLR} height={"40px"}></Brand></SwiperSlide>
            <SwiperSlide><Brand picture={brandAxial} height={"50px"}></Brand></SwiperSlide>
            <SwiperSlide><Brand picture={brandCider} height={"28px"}></Brand></SwiperSlide>
            <SwiperSlide><Brand picture={brandAssasImmobilier} height={"100px"}></Brand></SwiperSlide>
            <SwiperSlide><Brand picture={brandCRCDC} height={"64px"}></Brand></SwiperSlide>
            <SwiperSlide><Brand picture={brandVintageDrivers} height={"32px"}></Brand></SwiperSlide>
            <SwiperSlide><Brand picture={brandPipette} height={"42px"}></Brand></SwiperSlide>
            <SwiperSlide><Brand picture={brandMDF} height={"76px"}></Brand></SwiperSlide>
            <SwiperSlide><Brand picture={brandBarnes} height={"42px"}></Brand></SwiperSlide>
            <SwiperSlide><Brand picture={brandCos} height={"100px"}></Brand></SwiperSlide>
            <SwiperSlide><Brand picture={brandSadone} height={"46px"}></Brand></SwiperSlide>
            <SwiperSlide><Brand picture={brandMichalak} height={"72px"}></Brand></SwiperSlide>
            <SwiperSlide><Brand picture={brandProtextiles} height={"36px"}></Brand></SwiperSlide>
            <SwiperSlide><Brand picture={brandArtling} height={"52px"}></Brand></SwiperSlide>
          </Swiper>
        </div>
      </section>
      
      <section id="contact" className="grid">
        <div className="row row-1 ptop-0 pbot-20">
          <div className='contact spacing-4 ptop-8 pbot-8 ph-4 text-center nether border-radius'>
            <div className="inshape">
              <div className="shape-1"></div>
              <div className="shape-2"></div>
            </div>
            <div><h2>On reste en contact ?</h2></div>
            <div className='row row-3 ptop-0 pbot-0 spacing-4'>
              <div className='spacing-2'>
                <ChipIcon icon={iconPhoneEnabledSharp} type="yellow"></ChipIcon>
                <div><h3>Tél.</h3></div>
                <div><a href="tel:+33602311519">+33 6 02 31 15 19</a></div>
              </div>
              <div className='spacing-2'>
                <ChipIcon icon={iconMailOutline} type="yellow"></ChipIcon>
                <div><h3>Email</h3></div>
                <div><a href="mailto:corentin.gouloumy@gmail.com">corentin.gouloumy@gmail.com</a></div>
              </div>
              <div className='spacing-2'>
                <ChipIcon icon={iconLinkedIn} type="yellow"></ChipIcon>
                <div><h3>LinkedIn</h3></div>
                <div><a href="https://www.linkedin.com/in/corentin-gouloumy/" target="_blank">corentin-gouloumy</a></div>
              </div>
            </div>
            <div class="flex-row col-small">
              <div className="flex-row center">
                <ChipLink type="light" text="Contactez-moi !" link="https://www.linkedin.com/in/corentin-gouloumy/" target="blank"></ChipLink>
              </div>
            </div>
          </div>
        </div>
        <div className="shape bottom"></div>
      </section>
    </div>
  );
}

export default App;
