function Brand ({picture, height}) {
    return (
        <div className="brand">
            <img 
            src={picture} 
            style={
                {height: height}
            }/>
        </div>
    )
}

export default Brand