import ChipIcon from "./ChipIcon";

function LongCard({ icon, type, title, description }) {
    description = description.split('\n').map(str => <p class="h4">{str}</p>);
	return (
        <div className='longcard flex-row g4'>
            <ChipIcon icon={icon} type={type}></ChipIcon>
            <div className='content spacing-0'>
                <div><h2 className='h2-5'>{title}</h2></div>
                <div>{description}</div>
            </div>
        </div>
	)
}

export default LongCard