import classnames from 'classnames';

function ChipIcon({ icon, type }) {
	return (
        <div className={classnames('chipicon', type)}>
            <img src={icon}/>
        </div>
	)
}

export default ChipIcon
