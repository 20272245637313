import ChipIcon from "./ChipIcon"

function Card({ type, text, icon }) {
	return (
        <div className="card flex-col spacing-2">
            <ChipIcon type={type} icon={icon}></ChipIcon>
            <div><h3>{text}</h3></div>
        </div>
	)
}

export default Card