import classnames from 'classnames';

function ChipLink({ type, text, link, target }) {
	if (target == "blank") {
		target = "_blank";
	} else {
		target = "_self";
	}
	return (
        <a href={link} className={classnames('chiplink', type)} rel="noopener noreferrer" target={target}>{text}</a>
	)
}

export default ChipLink


