function SocialLink({ icon, text, link }) {
	return (
        <a href={link} target="_blank" className='social'>
			<img src={icon}></img>
			<span className='text-2'>{text}</span>
		</a>
	)
}

export default SocialLink


