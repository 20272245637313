function Skill({ software, percent }) {
    const percentage = "p" + percent;
	return (
        <div className="skill spacing-1">
            <div class="flex-row">
                <p className="software text-2">{software}</p>
                <p className="percent text-2">{percent}%</p>
            </div>
            <div className="skillbar">
                <span percent={percentage}></span>
            </div>
        </div>
	)
}

export default Skill


